import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import emailjs from '@emailjs/browser';

const Formulario = () => {

  const sendEmail = (event) => {
    event.preventDefault();

    emailjs.sendForm('service_e294rfo', 'template_61ay46e', event.target, '009H0Pb9qW-yI_5OP')
      .then(response => console.log(response))
      .catch(error => console.log(error));
  }

  return (
    <section className='section_contacto'>
      <div className='contacto'>
        <div className='div_titulo_form'>
          <h2 className='h2_titulo_form'>Contáctanos</h2>
        </div>
        <Row className='row_contacto'>
          <Col lg={true}>
            <div>
              <img height="100" src="./my-project-1.png" alt="logo" className='m-2' />
              <h5>Defensoría Marcas</h5>
              <span>Somos un grupo de abogados que buscamos ayudarte a proteger tu ingenio a través del registro de marca.
                Empatizamos con tu situación y creemos firmemente que puedes crear algo maravilloso.</span>
            </div>
          </Col>
          <Col lg={true}>
            <form onSubmit={sendEmail} className="formulario">
              <input type="text" name='user_name' placeholder='Nombre' className='my-2'/>
              <input type="email" name='user_email' placeholder='Correo' className='my-2'/>
              <textarea name="user_mensaje" id="" cols="30" rows="5" placeholder='Escríbenos tu consulta' className='my-2'></textarea>
              <button className='my-2 btn_form'>Enviar</button>
            </form>
          </Col>
        </Row>
      </div>
      <div>

      </div>
    </section>
  )
}

export default Formulario