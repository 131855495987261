import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
//import CustomButtonExample from '../components/CustomButtonExample'
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css'
import Flujo from '../components/Flujo';
import Formulario from '../components/Formulario';
import Equipo from '../components/Equipo';
import Casos from '../components/Casos';

export default function Home() {

  const navigate = useNavigate();
  const irACarro = () => {
    navigate(`/detalle/`);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);

  return (
    <main className="div_home">
      <header className="header_home" data-aos="zoom-out-down">
        <Row>
          <Col>
            <h1>¡Protege tus ideas de un mercado competitivo!</h1><br />
            <h3 className='lema'>Con Defensoría Marcas en menos de 48 horas recibirás una protección por 10 años</h3><br />
            <Button variant='primary' className="p-3" onClick={irACarro}>Solicita tu Marca Registrada</Button>
          </Col>
        </Row>
      </header>
      
      <section className='section_tarifa_bg' id="tarifa">
        <section className='section_tarifa' data-aos="zoom-in">
          <Row>
            <Col lg={true}>
              <h2 className='h2_tarifa'>¿Por qué registrar mi marca?</h2>
              <div className='div_registro_marca'>Es <span className='negrita'>importante</span> esta inscripción por muchas razones aquí te nombramos tres que son fundamentales para tu negocio: <br /> <br />
                1. El Registro de Marca otorga una protección jurídica a la persona que hace el debido registro, esto te servirá para poder <span className='negrita'>impedir que un tercero pueda utilizar tu marca y tu idea sin tu consentimiento</span> respecto a productos y servicios para los cuales la tienes registrada. <br /> <br />
                2. Las personas al momento de consumir un producto o servicio, determinan cómo hacerlo por la calidad y características que tenga la marca que ofrece tal producto. <br /><br />
                3. Será importante este registro para diferenciar los productos en el mercado y que los consumidores puedan saber qué marca elegir.
              </div>
            </Col>
            <Col lg={true}>
              <section className='card_group'>
                <Card className='card' style={{ width: '30rem' }}>
                  <Card.Body>
                    <Card.Title className='card_title'><h4>Registro Marca</h4></Card.Title>
                    <Card.Text>
                      <hr />
                      Te ofrecemos un servicio completo por 10 años.
                    </Card.Text>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header> ✅ Registro de Marca</Accordion.Header>
                        <Accordion.Body>
                          Hacer el registro completo de la marca en INAPI, esto comprende las 3 grandes etapas del registro: 1. Ingreso y examen de forma; 2. Publicación en Diario Oficial; 3. Examen de fondo.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header> ✅ Defensa en juicio</Accordion.Header>
                        <Accordion.Body>
                          En caso de haber alguna demanda de oposición, nuestros abogados te defenderán en todo el curso de la demanda, sin pagar extra.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header> ✅ Asesoría por 10 años</Accordion.Header>
                        <Accordion.Body>
                          Con esto todas las preguntas legales que tengas en torno a tu marca, serán contestadas a la brevedad por nuestro equipo legal.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header> ✅ Publicación Diario Oficial</Accordion.Header>
                        <Accordion.Body>
                          Todos los gastos asociados al Diario Oficial van cubiertos en la tarifa inicial, no tendrás que pagar más por esto.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header> ✅ Registro de logo</Accordion.Header>
                        <Accordion.Body>
                          En caso de que tu marca tenga un signo o logo, no tendrás que pagar de más por éste.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Card.Body>
                  <Card.Footer className='boton_card'>
                    <Button variant='danger' onClick={irACarro}>Solicita tu Registro!</Button>
                  </Card.Footer>
                </Card>
              </section>
            </Col>
          </Row>
        </section>
      </section>
      
      <Flujo />
      <Equipo />
      <Casos />
      <Formulario />
    </main>
  )
};