import { NavLink } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";


export default function NavBar() {
  const setActiveClass = ({ isActive }) => (isActive ? "active" : "non-active");
  const navigate = useNavigate();
  const irACarro = () => {
    navigate(`/detalle/`);
  };
  return (
    <Navbar collapseOnSelect expand="lg" className="Navbar fixed-top">
      <Container>
        <Navbar.Brand href="/"><img height="80" src="./my-project-1.png" alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavLink className={setActiveClass} to="/home">
              Home
            </NavLink>
            <NavLink className={setActiveClass} to="/detalle">
              Tarifas
            </NavLink>
          </Nav>
          <Nav>
            <Button className="btn_nav" onClick={irACarro}>Protege tu Marca</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}