import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Casos = () => {
  return (
    <section className='section_casos_bg'>
    <section className='section_casos' data-aos="zoom-in">
      <Row>
        <Col lg={true}>
          <h2 className='h2_tarifa'>Casos</h2>
          <div className='div_registro_marca'>Es <span className='negrita'>importante</span> esta inscripción por muchas razones aquí te nombramos tres que son fundamentales para tu negocio: <br /> <br />
            1. El Registro de Marca otorga una protección jurídica a la persona que hace el debido registro, esto te servirá para poder <span className='negrita'>impedir que un tercero pueda utilizar tu marca y tu idea sin tu consentimiento</span> respecto a productos y servicios para los cuales la tienes registrada. <br /> <br />
            2. Las personas al momento de consumir un producto o servicio, determinan cómo hacerlo por la calidad y características que tenga la marca que ofrece tal producto. <br /><br />
            3. Será importante este registro para diferenciar los productos en el mercado y que los consumidores puedan saber qué marca elegir.
          </div>
        </Col>
      </Row>
    </section>
  </section>
  )
}

export default Casos