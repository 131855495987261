import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const Equipo = () => {
  return (
    <section className='section_tarifa_bg'>
      <section className='section_tarifa' data-aos="zoom-in">
        <h2 className='h2_tarifa'>Equipo</h2>
        <Row>
          <Col lg={true}>
            <div className='seccion_equipo_bajada'>
              Contamos con un equipo multidisciplinario que promueve la <span className='negrita'>innovación tecnológica y la automatización de procesos</span>  para que la tramitación,
              atención al cliente y todos los ámbitos que tiene un servicio sean los más óptimos posibles para ser el mejor
              lugar para tí y tus ideas.
              <br />
              Defensoría Marcas
            </div>
          </Col>
          <Col lg={true}>
            <Card style={{ backgroundColor: '#F9F7F7' }}>
              <Card.Body>
                <img src="./paco.jpg" height={150} style={{ borderRadius: '100%' }} alt="" />
                <Card.Title className='my-3'><span className='negrita'>Francisco Camus</span></Card.Title>
                <hr />
                <Card.Text>
                  Abogado y Desarrollador Web - Universidad Diego Portales
                </Card.Text>
                <Card.Text>
                  Especialista en Propiedad Industrial
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={true}>
            <Card style={{ backgroundColor: '#F9F7F7' }}>
              <Card.Body>
                <img src="./gaston.jpg" height={150} style={{ borderRadius: '100%' }} alt="" />
                <Card.Title className='my-3'><span className='negrita'>Gastón Abadie</span></Card.Title>
                <hr />
                <Card.Text>
                  Abogado y Encargado de Automatización - Universidad de Concepción
                </Card.Text>
                <Card.Text>
                  Especialista en Propiedad Industrial
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </section>
  )
}

export default Equipo