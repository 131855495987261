import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/Navbar";
import Footer from './components/Footer';
import Home from "./views/Home";
import Detalle from './views/Detalle';
import NotFound from './views/NotFound';
import Marcas from './views/Marcas';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  return (
    <div className="App d-flex flex-column min-vh-100">
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/marcas" element={<Marcas />} />
            <Route path="/detalle" element={<Detalle />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
    </div>
  );
}