import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';

export default function Detalle() {

  const [servicio, setServicio] = useState(212450);
  const [clase, setClase] = useState(1);
  const [pack, setPack] = useState(150000);
  //que el valor sea 139.990 y cada clase 80.000
  const [tasa, setTasa] = useState(62450);
  const [total, setTotal] = useState(212450)

  /*
  const [info, setInfo] = useState([]);

  useEffect(() => {
    consultarInformacion();
  }, []);
  
  const consultarInformacion = async () => {
    const url = 'https://mindicador.cl/api/utm';
    const response = await fetch(url)
    const data = await response.json()
    setInfo(data); // con setInfo actualizamos el estado
  }
  */

  function sumaClase() {
    setClase(clase + 1)
    setPack(pack + 80000)
    setTasa(tasa + 62450)
    setTotal(total + 80000 + 62450)
  }

  function restarClase() {
    setServicio(servicio - servicio + 211954)
    setClase(1);
    setPack(150000);
    setTasa(62450);
    setTotal(211954);
  }


  return (
    <div className="m-5 card_detalle_pago">
      <section className='section_detalle'>
        <Row>
          <Col xs={12} md={8}>
            <h2 className='h2_detalle'>Solicitud de Marca</h2>
            <hr />
            <div className='div_detalle'>
              <ol>
                <div>Qué viene incluido en nuestros servicio:</div>
                <li><span className='negrita'>Registro de Marca:</span> Hacer el registro completo de la marca en INAPI, esto comprende las 3 grandes etapas del registro: 1. Ingreso y examen de forma; 2. Publicación en Diario Oficial; 3. Examen de fondo.</li>
                <li><span className='negrita'>Defensa en juicio:</span> En caso de haber alguna demanda de oposición, nuestros abogados te defenderán en todo el curso de la demanda, sin pagar extra.</li>
                <li><span className='negrita'>Asesoría por 10 años:</span> Con esto todas las preguntas legales que tengas en torno a tu marca, serán contestadas a la brevedad por nuestro equipo legal.</li>
                <li><span className='negrita'>Publicación Diario Oficial:</span> Todos los gastos asociados al Diario Oficial van cubiertos en la tarifa inicial, no tendrás que pagar más por esto.</li>
                <li><span className='negrita'>Registro de logo:</span> En caso de que tu marca tenga un signo o logo, no tendrás que pagar de más por éste.</li>
              </ol>
            </div>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title><h3 className='h3_card_detalle'>Tarifas Registro Marca</h3></Card.Title>
                <Card.Text>
                  <div>Detalle</div>
                  <div>
                  <span className='negrita'>Pack Marca Completo</span> x {clase} clase <span className='precios'>${pack.toLocaleString('de-DE')}</span>
                  </div>
                  <div>
                    Gobierno de Chile
                  </div>
                  <div>
                    Tasa inicial para {clase} clase <span span className='precios'>${tasa.toLocaleString('de-DE')}</span>
                  </div>
                  <hr />
                  <div>
                    <span className='negrita'>Total:</span> <span>${total.toLocaleString('de-DE')}</span>
                  </div>
                  <div>
                    *Tasa final no incluida
                  </div>
                </Card.Text>
                <a href="https://defensoriasalud.typeform.com/captacionmarcas" target="_blank" rel='noopener noreferrer'><Button variant="primary">Solicitar</Button></a> {' '}
                <Button variant="success" onClick={sumaClase}>Sumar Clase</Button>{' '}
                <Button variant="danger" onClick={restarClase}> X </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </div>
  );
}