import React from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css'
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';

const Flujo = () => {

  const navigate = useNavigate();
  const irACarro = () => {
    navigate(`/detalle/`);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);

  return (
    <section className='section_principal_flujo' data-aos="zoom-in">
      <div className='titulo_flujo'><h2 className='h2_titulo_flujo'>¿Cómo funciona Defensoría Marcas?</h2></div>
      <section className='real_section_flujo'>
        <Row className='section_flujo'>
          <Col className='card_flujo' lg={true}> <span className='numeros_flujo negrita'>1.</span> Una vez que hagas la solicitud, nuestro equipo legal hará la investigación correspondiente y <span className='negrita'>te contactará en 24 horas</span> para indicar la viabilidad del nombre de tu marca y el número de clase que necesita. </Col>
          <Col className='card_flujo' lg={true}> <span className='numeros_flujo negrita'>2.</span> Luego de aceptar las condiciones, el equipo legal de Defensoría Marcas, <span className='negrita'>hará la solicitud de registro de marca en INAPI</span>, con esto empezará el proceso legal para proteger tus ideas. </Col>
          <Col className='card_flujo' lg={true}> <span className='numeros_flujo negrita'>3.</span> A medida que vayan pasando las etapas administrativas de la solicitud te las iremos <span className='negrita'>comunicando debidamente</span>, hasta el final del proceso.</Col>
          <Col className='card_flujo' lg={true}> <span className='numeros_flujo negrita'>4.</span> Una vez que se registre tu marca, <span className='negrita'>tendrás una protección por el periodo de 10 años y renovable indefinidamente.</span> ¡Protege tus ideas, haz el registro de marca con nosotros!</Col>
        </Row>
      </section>
      <div className='div_btn_flujo'><Button variant='primary' className="" onClick={irACarro}>Solicita tu Registro de Marca</Button></div>
    </section>
  )
}

export default Flujo